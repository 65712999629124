import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ADD_STUDENT_CHARGE_IN_INVOICE_API_URL, BATCHES, BATCHES_API_URL, DELETE_STUDENT_FROM_BATCH_API_URL, GET_ALL_BATCHES_API_URL, GET_ALL_BATCH_INVOICE_API_URL, GET_GRADE_CLASSES_API_URL, POST_BATCH_API_URL, VIEW_STUDENT_INVOICE_API_URL } from 'constants/AppConstants';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import { env } from 'configs/EnvironmentConfig';
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";
import { act } from 'react';
const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  BatchesErrors: {},
  permission: permission?.batches ?? [],
  BatchesShowMessage: null,
  BatchesResult: [],
  gradeClassesResult: [],
  singleBatchStudentData: [],
  BatchesButtonSpinner: false,
  BatchesTableLoading: true,
  batchInvoiceTableLoading: true,
  viewInvoiceLoading: false,
  postBatchLoading: false,
  BatchesAddDrawer: false,
  viewStudentInvoiceData: [],
  BatchesEditData: [],
  BatchInvoicesData: {},
  BatchesButtonAndModelLabel: setLocale('charges_and_invoices.add_student_charges'),
  tablePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  tableInvoicePagination: {
    current: 1,
    pageSize: 10,
    total: 0,
  },
  sorting: {},
  filter: {},
  invoiceSorting: {},
  invoiceFilter: {},
  ViewBatchesData: [],
  ViewBatchesLoader: true,
  DrawerStatus: 0,
};

export const createBatches = createAsyncThunk(
  "createBatches",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(data, BATCHES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getBatches = createAsyncThunk(
  "getBatches",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_BATCHES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewBatches = createAsyncThunk(
  "viewBatches",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_ALL_BATCH_INVOICE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const addStudentChargeInInvoice = createAsyncThunk(
  "addStudentChargeInInvoice",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, ADD_STUDENT_CHARGE_IN_INVOICE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const postBatch = createAsyncThunk(
  "postBatch",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, POST_BATCH_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const viewStudentInvoice = createAsyncThunk(
  "viewStudentInvoice",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, VIEW_STUDENT_INVOICE_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);


export const getGradeClasses = createAsyncThunk(
  "getGradeClasses",
  async (data) => {
    try {
      const response = await CommonService.getAllPost(data, GET_GRADE_CLASSES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteBatches = createAsyncThunk(
  "deleteBatches",
  async (data) => {
    try {
      const response = await CommonService.deleteOne(data, BATCHES_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteStudentFromBatch = createAsyncThunk(
  "deleteStudentFromBatch",
  async (data) => {
    try {
      const response = await CommonService.postData(data, DELETE_STUDENT_FROM_BATCH_API_URL);
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const manageBatchesSlice = createSlice({
  name: BATCHES,
  initialState,
  reducers: {
    onCloseError: (state, action) => {
      state.BatchesErrors = {};
    },
    setSingleBatchStudentData: (state, action) => {
      state.singleBatchStudentData = action.payload;
    },
    BatchesAddDrawerStatus: (state, action) => {
      if (action.payload.status === false) {
        state.BatchesButtonSpinner = false;
      }
      // state.BatchesErrors = {};
      state.DrawerStatus = action.payload.errorStatus;
      state.BatchesAddDrawer = action.payload.status;
      state.BatchesEditData = [];
      state.BatchesButtonAndModelLabel = setLocale('charges_and_invoices.add_student_charges');
    },
    BatchesEditWithDrawerStatus: (state, action) => {
      // state.BatchesErrors = {};
      state.BatchesAddDrawer = true;
      state.DrawerStatus = action.payload.errorStatus;
      state.BatchesEditData = action.payload.data;
      state.BatchesButtonAndModelLabel = setLocale('batches.edit');
    },
    updateSortFilters: (state, action) => {
      state.filter = action.payload.filter;
      state.sorting = action.payload.sorting;
    },
    updateInvoiceSortFilters: (state, action) => {
      state.invoiceFilter = action.payload.filter;
      state.invoiceSorting = action.payload.sorting;
    },
    setColumnSearch: (state, action) => {
      state.filter = action.payload;
    },
    setInvoiceColumnSearch: (state, action) => {
      state.invoiceFilter = action.payload;
    },
    videPDF(state, action) {
      const dataToPass = {
        id: action.payload.enc_id,
        school_year_id: action.payload.student_school_year_id,
        organization_id: action.payload.organization_id,
      };

      // Constructing the query string from the data
      const queryString = Object.keys(dataToPass)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(dataToPass[key])}`)
        .join('&');

      // Constructing the final URL with the query string
      const url = `${env.API_ENDPOINT_URL}/view-student-invoice?${queryString}`;

      // Opening a new window with the specified URL
      window.open(url, '_blank');
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createBatches.pending, (state, action) => {
        state.DrawerStatus = 0
        state.BatchesButtonSpinner = true;
      }).addCase(createBatches.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.BatchesButtonSpinner = false;
        state.BatchesErrors = {}
      }).addCase(createBatches.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.BatchesShowMessage = true;
        state.BatchesButtonSpinner = false;
        state.BatchesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(getBatches.pending, (state, action) => {
        state.BatchesButtonSpinner = true;
        state.BatchesTableLoading = true;
      }).addCase(getBatches.fulfilled, (state, action) => {
        state.BatchesButtonSpinner = false;
        state.BatchesTableLoading = false;
        state.BatchesResult = action.payload;
        state.tablePagination = {
          ...state.tablePagination,
          total: action.payload.total,
          current: action.payload.current_page,
          pageSize: action.payload.per_page
        };
      }).addCase(getBatches.rejected, (state, action) => {
        state.BatchesShowMessage = true; // Set the showMessage flag to display the errors
        state.BatchesButtonSpinner = false;
        state.BatchesTableLoading = false;
        state.BatchesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      })

      .addCase(viewBatches.pending, (state, action) => {
        state.batchInvoiceTableLoading = true;
      }).addCase(viewBatches.fulfilled, (state, action) => {
        state.ViewBatchesData = action.payload;
        state.batchInvoiceTableLoading = false;
        state.tableInvoicePagination = {
          ...state.tableInvoicePagination,
          total: action.payload.pagination.total,
          current: action.payload.pagination.current_page,
          pageSize: action.payload.pagination.per_page
        };
      }).addCase(viewBatches.rejected, (state, action) => {
        state.batchInvoiceTableLoading = false;
      })

      .addCase(postBatch.pending, (state, action) => {
        state.BatchesTableLoading = true;
      }).addCase(postBatch.fulfilled, (state, action) => {
        state.BatchesTableLoading = false;
      }).addCase(postBatch.rejected, (state, action) => {
        state.BatchesTableLoading = false;
      })

      .addCase(getGradeClasses.pending, (state, action) => {
      }).addCase(getGradeClasses.fulfilled, (state, action) => {
        state.gradeClassesResult = action.payload;
      }).addCase(getGradeClasses.rejected, (state, action) => {
      })

      .addCase(viewStudentInvoice.pending, (state, action) => {
        state.viewInvoiceLoading = true;
      }).addCase(viewStudentInvoice.fulfilled, (state, action) => {
        state.viewStudentInvoiceData = action.payload;
        state.viewInvoiceLoading = false;
      }).addCase(viewStudentInvoice.rejected, (state, action) => {
        state.viewInvoiceLoading = false;
      })

      .addCase(deleteBatches.pending, (state, action) => {
      }).addCase(deleteBatches.fulfilled, (state, action) => {

      }).addCase(deleteBatches.rejected, (state, action) => {

      })

      .addCase(deleteStudentFromBatch.pending, (state, action) => {
      }).addCase(deleteStudentFromBatch.fulfilled, (state, action) => {

      }).addCase(deleteStudentFromBatch.rejected, (state, action) => {

      })
      .addCase(addStudentChargeInInvoice.pending, (state, action) => {
        state.DrawerStatus = 0
        state.BatchesButtonSpinner = true;
      }).addCase(addStudentChargeInInvoice.fulfilled, (state, action) => {
        state.DrawerStatus = 0
        state.BatchesButtonSpinner = false;
        state.BatchesErrors = {}
      }).addCase(addStudentChargeInInvoice.rejected, (state, action) => {
        state.DrawerStatus = 1
        state.BatchesShowMessage = true;
        state.BatchesButtonSpinner = false;
        state.BatchesErrors = JSON.parse(action.error.message); // Parse the error messages and store them in the state
      });
  },
});

export const { setSingleBatchStudentData, onCloseError, videPDF, updateInvoiceSortFilters, setInvoiceColumnSearch, setColumnSearch, BatchesAddDrawerStatus, BatchesEditWithDrawerStatus, updateSortFilters } = manageBatchesSlice.actions;

export default manageBatchesSlice.reducer;
