export const REPORT_CARD_BATCH_API_URL = "report-card-batch";
export const STUDENT_REPORT_CARD_DETAIL_API_URL = "save-student-assessment";
export const GET_ALL_REPORT_CARD_BATCH_API_URL = "get-all-report-card-batch";
export const GET_GRADE_BATCHS_API_URL = "get_grade_sub_batches";
export const GET_SUB_BATCH_SINGLE_CLASS = "get-sub-batch-single-class";
export const PRE_LOAD_DATA = "pre-load-data";
export const DOWNLOAD_REPORT_CARD_BATCH = "download-report-card-batch";
export const REPORT_CARD_BATCH = "manageReportCardBatchSlice";
export const HOLD_REPORT_CARD = "hold-report-card";
export const APPROVE_SUB_BATCH = "approve-sub-batch";
export const ROLL_BACK_APPROVE_SUB_BATCH = "roll-back-approve-sub-batch";
export const APPROVE_BATCH = "approve-batch";
export const PUBLISH_BATCH = "publish-batch";
export const RECALCULATE_STUDENT_ATTENDANCE = "recalculate-student-attendance";
export const UPDATE_STUDENT_REPORT_CARD_ATTENDANCE = "update-student-report-card-attendance";
export const SINGLE_STUDENT_REPORT_CARD_DETAIL = "single-student-report-card-detail";
export const BULK_APPROVE_ALL_CLASSES = "bulk-approve-all-classes";
export const GET_CLASS_STUDENTS = "get-clas-students"
export const ADD_STUDENTS_INTO_BATCH = "add-students-into-batch"
export const GET_SINGLE_CLASS_ONLY_STUDENTS = "get-single-class-only-students"