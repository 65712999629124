import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { 
  CHAT, 
  CREATE_CONVERSATION,   
  GET_USER_CONVERSATIONS,
  GET_CONVERSATION_MESSAGES,
  SEND_MESSAGE,
  MARK_READ_MESSAGES,
  TOTAL_UNREAD_MESSAGES
} from 'constants/chat/index';
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from 'services/CommonService/CommonService';
import IntlMessage from "components/util-components/IntlMessage";

const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE));

export const initialState = {
  ChatErrors       : {},
  permission: permission?.chat ?? [],
  ChatShowMessage  : null,

  conversationLoader: false,
  userConversations: [],

  selectedConversation: null,
  conversationBodyLoader: false,
  conversationBody: [],

  totalUnreadMessages: 0
};

export const getTotalUnreadMessages = createAsyncThunk("getTotalUnreadMessages", async (data) => {
  try {
    return await CommonService.postData(data, TOTAL_UNREAD_MESSAGES);      
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); 
  }
});
export const markReadMessages = createAsyncThunk("markReadMessages", async (data) => {
  try {
    return await CommonService.postData(data, MARK_READ_MESSAGES);      
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); 
  }
});
export const sendMessage = createAsyncThunk("sendMessage", async (data) => {
  try {
    return await CommonService.postData(data, SEND_MESSAGE);      
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); 
  }
});
export const getConversationMessages = createAsyncThunk("getConversationMessages", async (data) => {
  try {
    return await CommonService.postData(data, GET_CONVERSATION_MESSAGES);      
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); 
  }
});
export const getUserConversations = createAsyncThunk("getUserConversations", async (data) => {
  try {
    return await CommonService.postData(data, GET_USER_CONVERSATIONS);      
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); 
  }
});
export const createConversation = createAsyncThunk("createConversation", async (data) => {
  try {
    return await CommonService.postData(data, CREATE_CONVERSATION);      
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); 
  }
});


export const manageChatSlice = createSlice({
  name: CHAT,
  initialState,
  reducers: {
    setAddTotalUnreadMessages : (state, action) => {
      state.totalUnreadMessages += action.payload
    },
    setConversationOrder: (state, action) => {      
      const conversationIndex = state.userConversations.findIndex(conversation => conversation.conversation_id === action.payload?.conversation_id);
      if (conversationIndex > -1) {
        state.userConversations[conversationIndex] = {
          ...state.userConversations[conversationIndex],
          last_message: action.payload.message,
          last_message_at: action.payload.created_at
        };
      }
    },
    setUserConversations: (state, action) => {      
      const conversationIndex = state.userConversations.findIndex(conversation => conversation.conversation_id === action.payload?.conversation_id);
      if (conversationIndex > -1) {
        state.userConversations[conversationIndex] = {
          ...state.userConversations[conversationIndex],
          last_message: action.payload.message,
          last_message_at: action.payload.created_at,
          unread_messages: state.userConversations[conversationIndex].unread_messages + 1,
        };
      }
    },
   setPushNewMessage: (state, action) => {
     state.conversationBody = [...state.conversationBody, action.payload];
   },
   setSelectedConversation: (state, action) => {
     state.selectedConversation = action.payload;
     state.userConversations = state.userConversations.map((item) => {
       if (item.id === action.payload.id) {
        state.totalUnreadMessages -= item.unread_messages;
         return { ...item, unread_messages: 0 };
       }
       return item;
     })
   }
  },
  extraReducers: (builder) => {
    builder
    
    .addCase(getTotalUnreadMessages.pending, (state, action) => {    
      state.totalUnreadMessages = 0;  
    }).addCase(getTotalUnreadMessages.fulfilled, (state, action) => {      
      state.totalUnreadMessages = action.payload;
    }).addCase(getTotalUnreadMessages.rejected, (state, action) => {      
    })
    .addCase(sendMessage.pending, (state, action) => {      
    }).addCase(sendMessage.fulfilled, (state, action) => {      
    }).addCase(sendMessage.rejected, (state, action) => {      
    })
    .addCase(getConversationMessages.pending, (state, action) => {
      state.conversationBodyLoader = true;
    }).addCase(getConversationMessages.fulfilled, (state, action) => {
      state.conversationBodyLoader = false;    
      state.conversationBody = action.payload;  
    }).addCase(getConversationMessages.rejected, (state, action) => {
      state.conversationBodyLoader = false;
    })
    .addCase(getUserConversations.pending, (state, action) => {
      state.conversationLoader = true;
    }).addCase(getUserConversations.fulfilled, (state, action) => {
      state.conversationLoader = false;    
      state.userConversations = action.payload;  
    }).addCase(getUserConversations.rejected, (state, action) => {
      state.conversationLoader = false;
    })
    .addCase(createConversation.pending, (state, action) => {
      state.conversationLoader = true;
    }).addCase(createConversation.fulfilled, (state, action) => {
      state.conversationLoader = false;
      state.userConversations.unshift(action.payload);
      state.selectedConversation = action.payload;      
    }).addCase(createConversation.rejected, (state, action) => {
      state.conversationLoader = false;
    })   
  },
});

export const { 
  setSelectedConversation,
  setPushNewMessage,  
  setUserConversations,
  setConversationOrder,
  setAddTotalUnreadMessages
 } = manageChatSlice.actions;

export default manageChatSlice.reducer;
