import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  THEME_SETTING,
  THEME_SETTING_API_URL,
  GET_ALL_LOGOS_API_URL,
  UPLOAD_LOGO_API_URL,
  DELETE_LOGO_API_URL,
  GET_COMMUNICATION_MODULES_API_URL,
  UPDATE_COMMUNICATION_MODULES_API_URL,
  GET_SMTP_API_URL,
  SAVE_SMTP_API_URL,
  GET_TIMEZONE_API_URL,
  SAVE_TIMEZONE_API_URL,
} from "constants/AppConstants";
import { USER_ALL_INFORMATION_ORGANIZATION_WISE } from "constants/AuthConstant";
import CommonService from "services/CommonService/CommonService";
import IntlMessage from "components/util-components/IntlMessage";
import { THEME_CONFIG } from "configs/AppConfig";

const setLocale = (localeKey, isLocaleOn = true) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();
const permission = JSON.parse(
  localStorage.getItem(USER_ALL_INFORMATION_ORGANIZATION_WISE)
);

export const initialState = {
  logosLoading: false,
  logosResult: [],
  communicationModulesLoading: false,
  communicationModulesResult: [],
  smtpLoading: true,
  isLoading: false,
  smtpResult: [],
  smtpButtonSpinner: false,
  timezoneLoading: false,
  timezoneResult: "",
  timezoneButtonSpinner: false,
};

export const updateThemeSetting = createAsyncThunk(
  "updateThemeSetting",
  async (data) => {
    try {
      const response = await CommonService.createAndUpdate(
        data,
        THEME_SETTING_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const getAllLogos = createAsyncThunk("getAllLogos", async (data) => {
  try {
    return await CommonService.getData(data, GET_ALL_LOGOS_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const getCommunicationModules = createAsyncThunk(
  "getCommunicationModules",
  async (data) => {
    try {
      return await CommonService.getData(
        data,
        GET_COMMUNICATION_MODULES_API_URL
      );
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const uploadLogo = createAsyncThunk("uploadLogo", async (data) => {
  try {
    const response = await CommonService.createAndUpdate(
      data,
      UPLOAD_LOGO_API_URL
    );
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const updateCommunicationModule = createAsyncThunk(
  "updateCommunicationModule",
  async (data) => {
    try {
      const response = await CommonService.postData(
        data,
        UPDATE_COMMUNICATION_MODULES_API_URL
      );
      return response;
    } catch (err) {
      throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
    }
  }
);

export const deleteLogo = createAsyncThunk("deleteLogo", async (data) => {
  try {
    const response = await CommonService.deleteData(data, DELETE_LOGO_API_URL);
    return response;
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const getSmtp = createAsyncThunk("getSmtp", async (data) => {
  try {
    return await CommonService.getData(data, GET_SMTP_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const saveSmtp = createAsyncThunk("saveSmtp", async (data) => {
  try {
    return await CommonService.createAndUpdate(data, SAVE_SMTP_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const getTimezone = createAsyncThunk("getTimezone", async (data) => {
  try {
    return await CommonService.getData(data, GET_TIMEZONE_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const saveTimezone = createAsyncThunk("saveTimezone", async (data) => {
  try {
    return await CommonService.createAndUpdate(data, SAVE_TIMEZONE_API_URL);
  } catch (err) {
    throw new Error(JSON.stringify(err.response.data.errors)); // Throw an error with the server response errors
  }
});

export const manageThemeSettingSlice = createSlice({
  name: THEME_SETTING,
  initialState,
  reducers: {
    onBlankLayout: (state, action) => {
      state.blankLayout = action.payload;
      // if (userTheme) {
      // 	userTheme.blank_layout = action.payload
      // 	localStorage.setItem(USER_THEME, JSON.stringify(userTheme));
      // }
    },
    onIsLoadingChange: (state, action) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLogos.pending, (state, action) => {
        state.logosLoading = true;
      })
      .addCase(getAllLogos.fulfilled, (state, action) => {
        state.logosLoading = false;
        state.logosResult = action.payload;
      })
      .addCase(getAllLogos.rejected, (state, action) => {
        state.logosLoading = false;
      })

      .addCase(getCommunicationModules.pending, (state, action) => {
        state.communicationModulesLoading = true;
      })
      .addCase(getCommunicationModules.fulfilled, (state, action) => {
        state.communicationModulesLoading = false;
        state.communicationModulesResult = action.payload;
      })
      .addCase(getCommunicationModules.rejected, (state, action) => {
        state.communicationModulesLoading = false;
      })

      .addCase(deleteLogo.pending, (state, action) => {
        state.logosLoading = true;
      })
      .addCase(deleteLogo.fulfilled, (state, action) => {
        state.logosLoading = false;
        state.logosResult = action.payload;
      })
      .addCase(deleteLogo.rejected, (state, action) => {
        state.logosLoading = false;
      })

      .addCase(getSmtp.pending, (state, action) => {
        if (state.isLoading) {
          state.smtpLoading = true;
        }
      })
      .addCase(getSmtp.fulfilled, (state, action) => {
        state.smtpLoading = false;
        state.smtpResult = action.payload;
      })
      .addCase(getSmtp.rejected, (state, action) => {
        state.smtpLoading = false;
      })

      .addCase(saveSmtp.pending, (state, action) => {
        state.smtpButtonSpinner = true;
      })
      .addCase(saveSmtp.fulfilled, (state, action) => {
        state.smtpButtonSpinner = false;
      })
      .addCase(saveSmtp.rejected, (state, action) => {
        state.smtpButtonSpinner = false;
      })

      .addCase(getTimezone.pending, (state, action) => {
        state.timezoneLoading = true;
      })
      .addCase(getTimezone.fulfilled, (state, action) => {
        state.timezoneLoading = false;
        state.timezoneResult = action.payload;
      })
      .addCase(getTimezone.rejected, (state, action) => {
        state.timezoneLoading = false;
      })

      .addCase(saveTimezone.pending, (state, action) => {
        state.timezoneButtonSpinner = true;
      })
      .addCase(saveTimezone.fulfilled, (state, action) => {
        state.timezoneButtonSpinner = false;
      })
      .addCase(saveTimezone.rejected, (state, action) => {
        state.timezoneButtonSpinner = false;
      });
  },
});

export const { onBlankLayout, onIsLoadingChange } =
  manageThemeSettingSlice.actions;

export default manageThemeSettingSlice.reducer;
