// export const API_BASE_URL = "http://localhost/nexium/back/back/api/auth";
// export const FILE_BASE_URL = "http://localhost/nexium/back/back/public/storage/";
// export const IMPORT_SAMPLE_PARENT_FILE = "http://localhost/nexium/back/back/public/storage/demo-imports/import-parents.xlsx";
// export const IMPORT_SAMPLE_STUDENTS_FILE = "http://localhost/nexium/back/back/public/storage/demo-imports/import-students.xlsx";
// export const PUBLIC_LOGO_PATH = 'http://localhost/nexium/back/back/public/img/logo.png'
// export const PUBLIC_NAV_COLLAPSED_LOGO_PATH = 'http://localhost/nexium/back/back/public/img/logo-sm.png'
// export const PUBLIC_FAVICON_LOGO_PATH = 'http://localhost/nexium/back/back/public/img/favicon_logo.ico'
// export const img = "/img/avatars/user.png"
// export const APP_VERSION = "1.0.6"

// export const SOCKET_ENDPOINT_URL = 'http://localhost/nexium/back/back/api/'
// export const WS_HOST = 'ec2-13-58-194-234.us-east-2.compute.amazonaws.com'
// export const WS_PORT = process.env.REACT_APP_WS_PORT;
// export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
// export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;



// export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// export const FILE_BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
// export const IMPORT_SAMPLE_PARENT_FILE = process.env.REACT_APP_IMPORT_SAMPLE_PARENT_FILE;
// export const IMPORT_SAMPLE_STUDENTS_FILE = process.env.REACT_APP_IMPORT_SAMPLE_STUDENTS_FILE;

// export const PUBLIC_LOGO_PATH = process.env.REACT_APP_PUBLIC_LOGO_PATH;
// export const PUBLIC_NAV_COLLAPSED_LOGO_PATH = process.env.REACT_APP_PUBLIC_NAV_COLLAPSED_LOGO_PATH;
// export const PUBLIC_FAVICON_LOGO_PATH = process.env.REACT_APP_PUBLIC_FAVICON_LOGO_PATH;

// export const SOCKET_ENDPOINT_URL = process.env.REACT_APP_SOCKET_ENDPOINT_URL;
// export const WS_HOST = process.env.REACT_APP_WS_HOST;
// export const WS_PORT = process.env.REACT_APP_WS_PORT;
// export const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER;
// export const PUSHER_APP_KEY = process.env.REACT_APP_PUSHER_APP_KEY;

// export const img = process.env.REACT_APP_IMG
// export const APP_VERSION = process.env.REACT_APP_APP_VERSION



export const API_BASE_URL = "https://aes.threeapples.ca/back/api/auth";
export const FILE_BASE_URL = "https://aes.threeapples.ca/back/public/storage/";
export const IMPORT_SAMPLE_PARENT_FILE = "https://aes.threeapples.ca/back/public/storage/demo-imports/import-parents.xlsx";
export const IMPORT_SAMPLE_STUDENTS_FILE = "https://aes.threeapples.ca/back/public/storage/demo-imports/import-students.xlsx";
export const SOCKET_ENDPOINT_URL = 'https://aes.threeapples.ca/back/api/auth'
export const WS_HOST = 'https://aes.threeapples.ca/back/'
export const WS_PORT = 6001;
export const PUSHER_CLUSTER = 'mt1';
export const PUSHER_APP_KEY = 'b9e4f6f9f4b9f6f9f6f9';

export const PUBLIC_LOGO_PATH = 'https://aes.threeapples.ca/back/public/img/logo.png'
export const PUBLIC_NAV_COLLAPSED_LOGO_PATH = 'https://aes.threeapples.ca/back/public/img/logo-sm.png'
export const PUBLIC_FAVICON_LOGO_PATH = 'https://aes.threeapples.ca/back/public/img/favicon_logo.ico'
export const img = "/img/avatars/user.png"
export const APP_VERSION = "1.0.9"
